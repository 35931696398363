import {
  GET_CRM_ENQUIRY,
  GET_CRM_ENQUIRY_SOURCE,
  GET_CUSTOMER_BUDGET,
  GET_CRM_DIMENSION,
  GET_CUSTOMER_CONSTRUCTIONS_PLANNING,
  GET_CUSTOMER_PURCHASE_REASON,
  GET_CRM_SITE_SOLD,
  GET_CRM_CUSTOMER_ENQUIRY,
  GET_CRM_ENQUIRY_PROFILE,
  GET_CRM_ENQUIRY_PROFILE_EXPORT,
  GET_SITE_VISIT_FEEDBACK_QUESTION,
  GET_SITE_VISIT_FEEDBACK,
  GET_SITE_VISIT_FEEDBACK_EXPORT,
  GET_SITE_VISIT_FEEDBACK_DETAIL,
  GET_CRM_BOOKING_ENQUIRY_PROFILE,
  GET_CRM_MEETING,
  GET_CRM_MEETING_CATEGORY,
  GET_CRM_MEETING_ATTENDEE,
  GET_CRM_MEETING_DETAIL,
  GET_CRM_MEETING_EXPORT,
  GET_CRM_MEETING_SOURCE,
  GET_CRM_MEETING_CHANNEL_PARTNER_INFO,
  GET_CRM_MEETING_CHANNEL_CLIENT_INFO,
  GET_CRM_MEETING_PREFERRED_LOCATION,
  GET_CRM_MEETING_PREFERRED_LOCATION_EXPORT,
  GET_CRM_MEETING_STM_INFO,
  GET_CRM_SOURCE_GROUP,
  GET_CRM_SOURCE_GROUP_EXPORT,
  GET_CRM_SOURCE_CATEGORY,
  GET_CRM_SOURCE,
  GET_CRM_ENQUIRY_STATUS,
  GET_CRM_ENQUIRY_CALL_STATUS,
  GET_CRM_ENQUIRY_CATEGORY,
  GET_DIMENSION_MASTER,
  GET_RELEASE_STATUS,
  GET_CRM_SITE_PREMIUM_TYPE,
  GET_CRM_SITE_STATUS,
  GET_SITE_INVESTOR,
  GET_BLOCK_REASON,
  GET_ENQUIRY_FOLLOW_STATUS,
  GET_CRM_CHANNEL_PARTNER,
  GET_CRM_CLIENT,
  GET_CRM_STM,
} from "./types";

const initialState = {
  getCrmEnquiry: [],
  getCrmEnquirySource: [],
  getCustomerBudget: [],
  getCrmDimension: [],
  getCustomerConstructionPlanning: [],
  getCustomerPurchaseReason: [],
  getCrmSiteSold: [],
  getCrmCustomerEnquiry: [],
  getCrmEnquiryProfile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmEnquiryProfileExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSiteVisitFeedbackQuestion: [],
  getSiteVisitFeedback: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSiteVisitFeedbackExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getSiteVisitFeedbackDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmBookingEnquiryProfile: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeeting: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingAttendee: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingDetail: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingChannelPartnerInfo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingClientInfo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmPreferredLocation: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmPreferredLocationExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingStmInfo: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSourceGroup: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSourceGroupExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSourceCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getCrmSource: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmEnquiryStatus: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  getCrmEnquiryCategory: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmEnquiryCallStatus: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmDimensionMaster: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmReleaseStatus: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSitePremiumType: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSiteStatus: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmSiteInvestor: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmBlockReason: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getEnquiryFollowStatus: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingChannelPartner: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingClient: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCrmMeetingStm: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};
const CRM_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CRM_ENQUIRY:
      return Object.assign({}, state, { getCrmEnquiry: payload });
    case GET_CRM_ENQUIRY_SOURCE:
      return Object.assign({}, state, { getCrmEnquirySource: payload });
    case GET_CUSTOMER_BUDGET:
      return Object.assign({}, state, { getCustomerBudget: payload });
    case GET_CRM_DIMENSION:
      return Object.assign({}, state, { getCrmDimension: payload });
    case GET_CUSTOMER_CONSTRUCTIONS_PLANNING:
      return Object.assign({}, state, {
        getCustomerConstructionPlanning: payload,
      });
    case GET_CUSTOMER_PURCHASE_REASON:
      return Object.assign({}, state, { getCustomerPurchaseReason: payload });
    case GET_CRM_SITE_SOLD:
      return Object.assign({}, state, { getCrmSiteSold: payload });
    case GET_CRM_CUSTOMER_ENQUIRY:
      return Object.assign({}, state, { getCrmCustomerEnquiry: payload });
    case GET_CRM_ENQUIRY_PROFILE:
      return Object.assign({}, state, { getCrmEnquiryProfile: payload });
    case GET_CRM_ENQUIRY_PROFILE_EXPORT:
      return Object.assign({}, state, {
        getCrmEnquiryProfileExport: payload,
      });
    case GET_SITE_VISIT_FEEDBACK_QUESTION:
      return Object.assign({}, state, {
        getSiteVisitFeedbackQuestion: payload,
      });
    case GET_SITE_VISIT_FEEDBACK:
      return Object.assign({}, state, { getSiteVisitFeedback: payload });
    case GET_SITE_VISIT_FEEDBACK_EXPORT:
      return Object.assign({}, state, {
        getSiteVisitFeedbackExport: payload,
      });
    case GET_SITE_VISIT_FEEDBACK_DETAIL:
      return Object.assign({}, state, {
        getSiteVisitFeedbackDetail: payload,
      });
    case GET_CRM_BOOKING_ENQUIRY_PROFILE:
      return Object.assign({}, state, {
        getCrmBookingEnquiryProfile: payload,
      });
    case GET_CRM_MEETING:
      return Object.assign({}, state, { getCrmMeeting: payload });

    // case GET_CRM_PROJECTS:
    //     return Object.assign({}, state, { getCrmProjects: payload });

    case GET_CRM_MEETING_CATEGORY:
      return Object.assign({}, state, { getCrmMeetingCategory: payload });

    case GET_CRM_MEETING_ATTENDEE:
      return Object.assign({}, state, { getCrmMeetingAttendee: payload });
    case GET_CRM_MEETING_DETAIL:
      return Object.assign({}, state, { getCrmMeetingDetail: payload });
    case GET_CRM_MEETING_EXPORT:
      return Object.assign({}, state, { getCrmMeetingExport: payload });
    case GET_CRM_MEETING_SOURCE:
      return Object.assign({}, state, { getCrmMeetingSource: payload });
    case GET_CRM_MEETING_CHANNEL_PARTNER_INFO:
      return Object.assign({}, state, {
        getCrmMeetingChannelPartnerInfo: payload,
      });
    case GET_CRM_MEETING_CHANNEL_CLIENT_INFO:
      return Object.assign({}, state, { getCrmMeetingClientInfo: payload });
    case GET_CRM_MEETING_PREFERRED_LOCATION:
      return Object.assign({}, state, { getCrmPreferredLocation: payload });
    case GET_CRM_MEETING_PREFERRED_LOCATION_EXPORT:
      return Object.assign({}, state, {
        getCrmPreferredLocationExport: payload,
      });
    case GET_CRM_MEETING_STM_INFO:
      return Object.assign({}, state, { getCrmMeetingStmInfo: payload });
    case GET_CRM_SOURCE_GROUP:
      return Object.assign({}, state, { getCrmSourceGroup: payload });

    case GET_CRM_SOURCE_GROUP_EXPORT:
      return Object.assign({}, state, { getCrmSourceGroupExport: payload });
    case GET_CRM_SOURCE_CATEGORY:
      return Object.assign({}, state, { getCrmSourceCategory: payload });
    case GET_CRM_SOURCE:
      return Object.assign({}, state, { getCrmSource: payload });
    case GET_CRM_ENQUIRY_STATUS:
      return Object.assign({}, state, { getCrmEnquiryStatus: payload });
    case GET_CRM_ENQUIRY_CATEGORY:
      return Object.assign({}, state, { getCrmEnquiryCategory: payload });
    case GET_CRM_ENQUIRY_CALL_STATUS:
      return Object.assign({}, state, { getCrmEnquiryCallStatus: payload });
    case GET_DIMENSION_MASTER:
      return Object.assign({}, state, { getCrmDimensionMaster: payload });

    case GET_RELEASE_STATUS:
      return Object.assign({}, state, { getCrmReleaseStatus: payload });
    case GET_CRM_SITE_PREMIUM_TYPE:
      return Object.assign({}, state, { getCrmSitePremiumType: payload });
    case GET_CRM_SITE_STATUS:
      return Object.assign({}, state, { getCrmSiteStatus: payload });
    case GET_SITE_INVESTOR:
      return Object.assign({}, state, { getCrmSiteInvestor: payload });
    case GET_BLOCK_REASON:
      return Object.assign({}, state, { getCrmBlockReason: payload });
      case GET_ENQUIRY_FOLLOW_STATUS:
        return Object.assign({}, state, { getEnquiryFollowStatus: payload });
        case GET_CRM_CHANNEL_PARTNER:
          return Object.assign({}, state, { getCrmMeetingChannelPartner: payload });
          case GET_CRM_CLIENT:
            return Object.assign({}, state, { getCrmMeetingClient: payload });
            case GET_CRM_STM:
              return Object.assign({}, state, { getCrmMeetingStm: payload });
    default:
      return state;
  }
};
export default CRM_reducer;
