import {
  GET_ACCOUNT_PAYMENT,
  GET_COMPANY_LIST,
  GET_PAYMENT_CATEGORY_LIST,
  GET_ACCOUNT_PAYMENT_DETAIL,
  GET_COMPANY_BANK_ACCOUNT_LIST,
  GET_PAYMENT_MODE_CATEGORY_LIST,
  GET_PAYMENT_MODE_LIST,
  GET_ACCOUNT_PAYMENT_PAID_LIST,
  GET_ACCOUNT_PAYMENT_PAID_DETAIL,
  GET_PROFITABLE_REPORT_GROUP,
  GET_GROUP_EXPORT,
  GET_PROFITABLE_REPORT_CATEGORY,
  GET_PROFITABLE_REPORT_CATEGORY_EXPORT,
  GET_PROFITABLE_REPORT_ITEM,
  GET_PROFITABILITY_REPORT,
  GET_PROJECT_LOAN,
  GET_PROJECT_LOAN_EXPORT,
  GET_PROJECT_LOAN_STATUS,
  GET_PROJECT_LOAN_DETAIL,
  GET_PROJECT_LOAN_PROCESS,
  GET_PROJECT_LOAN_PROCESS_EXPORT,
  GET_LOAN_PROCESS_FOR_PROCESS_PLAN_ADD,
  GET_LOAN_PANEL_FOR_PROCESS_PLAN_ADD,
  GET_PROJECT_MORTGAGE,
  GET_PROJECT_MORTGAGE_EXPORT,
  GET_PROJECT_MORTGAGE_DETAILS,
  GET_LOAN_PROCESS_STATUS,
  GET_LOAN_PROCESS_STATUS_EXPORT,
  GET_LOAN_PROCESS_PLAN_DETAIL,
  GET_PROJECT_MORTGAGE_ELIGIBLE_FILE,
  GET_LOAN_PROCESS_STATUS_FOR_UPDATE,
  GET_PROJECT_LOAN_FILTER_PROJECT,
  GET_PROJECT_LOAN_FILTER_BANK,
  GET_LOAN_PROCESS_PLAN_FILTER_PROJECT,
  GET_LOAN_PROCESS_PLAN_FILTER_BANK,
  GET_LOAN_PROCESS_PLAN_FILTER_PANEL,
  GET_LOAN_PROCESS_PLAN_FILTER_PROCESS,
  GET_LOAN_PROCESS_PLAN_FOR_QUERY_CREATE,
  GET_LOAN_PROCESS_PLAN_QUERY,
  GET_LOAN_PROCESS_PLAN_QUERY_EXPORT,
  GET_LOAN_PROCESS_PLAN_QUERY_DETAIL,
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT,
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_BANK,
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PANEL,
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE,
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE,
  GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS,
  GET_LOAN_PROCESS_PLAN_FILTER_RESPONSIBLE,
  GET_LOAN_PROCESS_PLAN_FILTER_ACCOUNTABLE,
  GET_LOAN_PROCESS_PLAN_QUERY_TEMPLATE,
  GET_LOAN_PROCESS_PLAN_QUERY_REPORT,

  // Vendor Nithish
  GET_VENDOR,
  GET_CONSTITUTION_OF_FIRM,
  GET_VENDOR_DOCUMENTS,
  GET_VENDOR_DOCUMENTS_TYPE,
  GET_VENDOR_SERVICE,
  GET_VENDOR_SERVICE_MASTER,
  GET_VENDOR_CONTACT,
  GET_VENDOR_ACCOUNT,
  GET_VENDOR_ACCOUNT_DOCUMENT_TYPE,
  GET_VENDOR_ADDRESS,
  GET_STATE,
  GET_VENDOR_ACTION_HISTORY,
  GET_ACCOUNT_PAYMENT_FILTER_COMPANY,
  GET_ACCOUNT_PAYMENT_FILTER_PROJECT,
  GET_ACCOUNT_PAYMENT_FILTER_REQUESTED_BY,
  GET_ACCOUNT_PAYMENT_FILTER_CATEGORY,
  GET_VENDOR_DETAILS,
  GET_VENDOR_CHANGE_REQUEST,
  GET_VENDOR_CHANGE_REQUEST_DETAILS,
  GET_CIVIL_PROJECT_BILL_ACCOUNT,
  GET_CIVIL_PROJECT_BILL_ACCOUNT_EXPORT,
} from "./types";

const initialStateChart = {
  labels: [],
  datasets: [],
};

const initialState = {
  accountPaymentList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  companyList: {
    error: false,
    message: "",
    company: [],
    totalCount: 0,
  },
  paymentCategoryList: {
    error: false,
    message: "",
    company: [],
    totalCount: 0,
  },
  accountPaymentDetail: {
    error: false,
    message: "",
    data: [],
  },
  companyAccountList: {
    error: false,
    message: "",
    company: [],
    totalCount: 0,
  },
  paymentModeCategoryList: {
    error: false,
    message: "",
    company: [],
    totalCount: 0,
  },
  paymentModeList: {
    error: false,
    message: "",
    company: [],
    totalCount: 0,
  },
  accountPaymentPaidList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },

  accountPaymentPaidDetail: {
    error: false,
    message: "",
    data: [],
  },
  getProfitableReportGroup: {
    error: false,
    message: "",
    data: [],
  },
  getGroupExport: {
    error: false,
    message: "",
    data: [],
  },
  getProfitableReportCategory: {
    error: false,
    message: "",
    data: [],
  },
  getProfitableReportCategoryExport: {
    error: false,
    message: "",
    data: [],
  },
  getProfitableReportItem: {
    error: false,
    message: "",
    data: [],
  },
  getProjectLoan: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getProjectLoanExport: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getProjectLoanStatus: {
    data: [],
    message: "",
    error: false,
    totalCount: 0,
  },
  getProjectLoanDetail: [],
  getProjectLoanProcess: {
    error: false,
    message: "",
    data: [],
  },
  getProjectLoanProcessExport: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessForProcessPlanAdd: {
    error: false,
    message: "",
    data: [],
  },
  getLoanPanelForProcessPlanAdd: {
    error: false,
    message: "",
    data: [],
  },
  getProjectMortgage: {
    error: false,
    message: "",
    data: [],
  },
  getProjectMortgageExport: {
    error: false,
    message: "",
    data: [],
  },
  getProjectMortgageDetails: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessStatus: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessStatusExport: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanDetail: {
    error: false,
    message: "",
    data: [],
  },
  getProjectMortgageEligibleFile: {
    error: false,
    message: "",
    data: [],
  },

  getLoanProcessStatusForUpdate: {
    error: false,
    message: "",
    data: [],
  },
  getProjectLoanFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  getProjectLoanFilterBank: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanFilterBank: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanFilterProcess: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanFilterPanel: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanForQueryCreate: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryList: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLoanProcessPlanQueryExport: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryDetail: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryFilterBank: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryFilterPanel: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryFilterResponsible: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryFilterAccountable: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryFilterProcess: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanFilterResponsible: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanFilterAccountable: {
    error: false,
    message: "",
    data: [],
  },
  getLoanProcessPlanQueryTemplete: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getLoanProcessPlanQueryReport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  // Vendor Nithish
  getVendor: {
    error: false,
    message: "",
    data: [],
  },
  getConstitutionOfFirm: {
    error: false,
    message: "",
    data: [],
  },
  getVendorDocuments: {
    error: false,
    message: "",
    data: [],
  },
  getVendorDocumentType: {
    error: false,
    message: "",
    data: [],
  },
  getVendorService: {
    error: false,
    message: "",
    data: [],
  },
  getVendorServiceMaster: {
    error: false,
    message: "",
    data: [],
  },
  getVendorContact: {
    error: false,
    message: "",
    data: [],
  },
  getVendorAccount: {
    error: false,
    message: "",
    data: [],
  },
  getVendorAccountDocumentType: {
    error: false,
    message: "",
    data: [],
  },
  getVendorAddress: {
    error: false,
    message: "",
    data: [],
  },
  getState: {
    error: false,
    message: "",
    data: [],
  },
  getVendorActionHistory: {
    error: false,
    message: "",
    data: [],
  },
  getAccountPaymentFilterCompany: {
    error: false,
    message: "",
    data: [],
  },
  getAccountPaymentFilterProject: {
    error: false,
    message: "",
    data: [],
  },
  getAccountPaymentFilterRequestedBy: {
    error: false,
    message: "",
    data: [],
  },
  getAccountPaymentFilterCategory: {
    error: false,
    message: "",
    data: [],
  },
  getVendorDetails: {
    error: false,
    message: "",
    data: [],
  },
  getVendorChangeRequest: {
    error: false,
    message: "",
    data: [],
  },
  getVendorChangeRequestDetails: {
    error: false,
    message: "",
    data: [],
  },
  getCivilProjectBillAccount: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
  getCivilProjectBillAccountExport: {
    error: false,
    message: "",
    data: [],
    totalCount: 0,
  },
};

const Report_reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ACCOUNT_PAYMENT:
      return Object.assign({}, state, { accountPaymentList: payload });
    case GET_COMPANY_LIST:
      return Object.assign({}, state, { companyList: payload });
    case GET_PAYMENT_CATEGORY_LIST:
      return Object.assign({}, state, { paymentCategoryList: payload });
    case GET_ACCOUNT_PAYMENT_DETAIL:
      return Object.assign({}, state, { accountPaymentDetail: payload });
    case GET_COMPANY_BANK_ACCOUNT_LIST:
      return Object.assign({}, state, { companyAccountList: payload });
    case GET_PAYMENT_MODE_CATEGORY_LIST:
      return Object.assign({}, state, { paymentModeCategoryList: payload });
    case GET_PAYMENT_MODE_LIST:
      return Object.assign({}, state, { paymentModeList: payload });
    case GET_ACCOUNT_PAYMENT_PAID_LIST:
      return Object.assign({}, state, { accountPaymentPaidList: payload });
    case GET_ACCOUNT_PAYMENT_PAID_DETAIL:
      return Object.assign({}, state, { accountPaymentPaidDetail: payload });
    case GET_PROFITABLE_REPORT_GROUP:
      return Object.assign({}, state, { getProfitableReportGroup: payload });
    case GET_GROUP_EXPORT:
      return Object.assign({}, state, { getGroupExport: payload });
    case GET_PROFITABLE_REPORT_CATEGORY:
      return Object.assign({}, state, { getProfitableReportCategory: payload });
    case GET_PROFITABLE_REPORT_CATEGORY_EXPORT:
      return Object.assign({}, state, {
        getProfitableReportCategoryExport: payload,
      });
    case GET_PROFITABLE_REPORT_ITEM:
      return Object.assign({}, state, { getProfitableReportItem: payload });
    case GET_PROJECT_LOAN:
      return Object.assign({}, state, { getProjectLoan: payload });
    case GET_PROJECT_LOAN_EXPORT:
      return Object.assign({}, state, { getProjectLoanExport: payload });
    case GET_PROJECT_LOAN_STATUS:
      return Object.assign({}, state, { getProjectLoanStatus: payload });
    case GET_PROJECT_LOAN_DETAIL:
      return Object.assign({}, state, { getProjectLoanDetail: payload });
    case GET_PROJECT_LOAN_PROCESS:
      return Object.assign({}, state, { getProjectLoanProcess: payload });
    case GET_PROJECT_LOAN_PROCESS_EXPORT:
      return Object.assign({}, state, { getProjectLoanProcessExport: payload });
    case GET_LOAN_PROCESS_FOR_PROCESS_PLAN_ADD:
      return Object.assign({}, state, {
        getLoanProcessForProcessPlanAdd: payload,
      });
    case GET_LOAN_PANEL_FOR_PROCESS_PLAN_ADD:
      return Object.assign({}, state, {
        getLoanPanelForProcessPlanAdd: payload,
      });
    case GET_PROJECT_MORTGAGE:
      return Object.assign({}, state, { getProjectMortgage: payload });
    case GET_PROJECT_MORTGAGE_EXPORT:
      return Object.assign({}, state, { getProjectMortgageExport: payload });
    case GET_PROJECT_MORTGAGE_DETAILS:
      return Object.assign({}, state, { getProjectMortgageDetails: payload });
    case GET_LOAN_PROCESS_STATUS_EXPORT:
      return Object.assign({}, state, { getLoanProcessStatusExport: payload });
    case GET_LOAN_PROCESS_STATUS:
      return Object.assign({}, state, { getLoanProcessStatus: payload });
    case GET_LOAN_PROCESS_PLAN_DETAIL:
      return Object.assign({}, state, { getLoanProcessPlanDetail: payload });
    case GET_PROJECT_MORTGAGE_ELIGIBLE_FILE:
      return Object.assign({}, state, {
        getProjectMortgageEligibleFile: payload,
      });

    case GET_LOAN_PROCESS_STATUS_FOR_UPDATE:
      return Object.assign({}, state, {
        getLoanProcessStatusForUpdate: payload,
      });
    case GET_PROJECT_LOAN_FILTER_PROJECT:
      return Object.assign({}, state, { getProjectLoanFilterProject: payload });
    case GET_PROJECT_LOAN_FILTER_BANK:
      return Object.assign({}, state, { getProjectLoanFilterBank: payload });
    case GET_LOAN_PROCESS_PLAN_FILTER_PROJECT:
      return Object.assign({}, state, {
        getLoanProcessPlanFilterProject: payload,
      });
    case GET_LOAN_PROCESS_PLAN_FILTER_BANK:
      return Object.assign({}, state, {
        getLoanProcessPlanFilterBank: payload,
      });
    case GET_LOAN_PROCESS_PLAN_FILTER_PROCESS:
      return Object.assign({}, state, {
        getLoanProcessPlanFilterProcess: payload,
      });
    case GET_LOAN_PROCESS_PLAN_FILTER_PANEL:
      return Object.assign({}, state, {
        getLoanProcessPlanFilterPanel: payload,
      });
    case GET_LOAN_PROCESS_PLAN_FOR_QUERY_CREATE:
      return Object.assign({}, state, {
        getLoanProcessPlanForQueryCreate: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY:
      return Object.assign({}, state, { getLoanProcessPlanQueryList: payload });
    case GET_LOAN_PROCESS_PLAN_QUERY_EXPORT:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryExport: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_DETAIL:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryDetail: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryFilterProject: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_FILTER_BANK:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryFilterBank: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PANEL:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryFilterPanel: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryFilterResponsible: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryFilterAccountable: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryFilterProcess: payload,
      });
    case GET_LOAN_PROCESS_PLAN_FILTER_RESPONSIBLE:
      return Object.assign({}, state, {
        getLoanProcessPlanFilterResponsible: payload,
      });
    case GET_LOAN_PROCESS_PLAN_FILTER_ACCOUNTABLE:
      return Object.assign({}, state, {
        getLoanProcessPlanFilterAccountable: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_TEMPLATE:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryTemplete: payload,
      });
    case GET_LOAN_PROCESS_PLAN_QUERY_REPORT:
      return Object.assign({}, state, {
        getLoanProcessPlanQueryReport: payload,
      });

    // Vendor Nithish

    case GET_VENDOR:
      return Object.assign({}, state, { getVendor: payload });
    case GET_CONSTITUTION_OF_FIRM:
      return Object.assign({}, state, { getConstitutionOfFirm: payload });
    case GET_VENDOR_DOCUMENTS:
      return Object.assign({}, state, { getVendorDocuments: payload });
    case GET_VENDOR_DOCUMENTS_TYPE:
      return Object.assign({}, state, { getVendorDocumentType: payload });
    case GET_VENDOR_SERVICE:
      return Object.assign({}, state, { getVendorService: payload });
    case GET_VENDOR_SERVICE_MASTER:
      return Object.assign({}, state, { getVendorServiceMaster: payload });
    case GET_VENDOR_CONTACT:
      return Object.assign({}, state, { getVendorContact: payload });
    case GET_VENDOR_ACCOUNT:
      return Object.assign({}, state, { getVendorAccount: payload });
    case GET_VENDOR_ACCOUNT_DOCUMENT_TYPE:
      return Object.assign({}, state, {
        getVendorAccountDocumentType: payload,
      });
    case GET_VENDOR_ADDRESS:
      return Object.assign({}, state, { getVendorAddress: payload });
    case GET_STATE:
      return Object.assign({}, state, { getState: payload });
    case GET_VENDOR_ACTION_HISTORY:
      return Object.assign({}, state, { getVendorActionHistory: payload });
    case GET_ACCOUNT_PAYMENT_FILTER_COMPANY:
      return Object.assign({}, state, {
        getAccountPaymentFilterCompany: payload,
      });
    case GET_ACCOUNT_PAYMENT_FILTER_PROJECT:
      return Object.assign({}, state, {
        getAccountPaymentFilterProject: payload,
      });
    case GET_ACCOUNT_PAYMENT_FILTER_REQUESTED_BY:
      return Object.assign({}, state, {
        getAccountPaymentFilterRequestedBy: payload,
      });
    case GET_ACCOUNT_PAYMENT_FILTER_CATEGORY:
      return Object.assign({}, state, {
        getAccountPaymentFilterCategory: payload,
      });
    case GET_VENDOR_DETAILS:
      return Object.assign({}, state, { getVendorDetails: payload });
    case GET_VENDOR_CHANGE_REQUEST:
      return Object.assign({}, state, { getVendorChangeRequest: payload });
    case GET_VENDOR_CHANGE_REQUEST_DETAILS:
      return Object.assign({}, state, {
        getVendorChangeRequestDetails: payload,
      });
    case GET_CIVIL_PROJECT_BILL_ACCOUNT:
      return Object.assign({}, state, { getCivilProjectBillAccount: payload });
    case GET_CIVIL_PROJECT_BILL_ACCOUNT_EXPORT:
      return Object.assign({}, state, {
        getCivilProjectBillAccountExport: payload,
      });
    default:
      return state;
  }
};

export default Report_reducer;
